import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  // ProductList as ProductListView,
  UserList as UserListView,
  InscripcionesList as InscripcionesListView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  // NuevoTaller as NuevoTallerView,
  // EditarTaller as EditarTallerView,
  // Directores as DirectoresView,
  // Films as FilmsView,
  Agenda as AgendaView,
  NuevoAgenda as NuevoAgendaView,
  EditarAgenda as EditarAgendaView,
  // Compras as ComprasView,
  Ingresos as IngresosView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/usuarios"
      />
      <RouteWithLayout
        component={InscripcionesListView}
        exact
        layout={MainLayout}
        path="/inscripciones"
      />
      <RouteWithLayout
        component={AgendaView}
        exact
        layout={MainLayout}
        path="/agenda"
      />
      <RouteWithLayout
        component={NuevoAgendaView}
        exact
        layout={MainLayout}
        path="/nuevo-evento"
      />
      <RouteWithLayout
        component={IngresosView}
        exact
        layout={MainLayout}
        path="/ingresos"
      />
      <RouteWithLayout
        component={EditarAgendaView}
        exact
        layout={MainLayout}
        path="/editar-agenda"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
