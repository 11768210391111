import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import charlaContext from '../../../../context/charlaContext';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const { className, users, titulosCharlas, ...rest } = props;

  const classes = useStyles();

  const CharlaContext = useContext(charlaContext);
  const {charla} = CharlaContext;

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Charla</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Apellido</TableCell>
                  <TableCell>País</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Celular</TableCell>
                  <TableCell>Cargo</TableCell>
                  <TableCell>Institución</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(user => {
                  if(charla === 0 || charla === user.charla) {
                    return (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={user.id}
                        >
                        <TableCell>{moment(user.createdAt).format('DD/MM/YYYY')}</TableCell>
                        
                        <TableCell>
                        {
                          (titulosCharlas !== undefined) ? (
                            titulosCharlas.map(charla => {
                              if(charla.id === user.charla) {
                                return charla.es_titulo
                              }
                            })
                          ) : null
                        }
                        </TableCell>

                        <TableCell>
                          <div className={classes.nameContainer}>
                            <Typography variant="body1">{user.nombre}</Typography>
                          </div>
                        </TableCell>
                        <TableCell>{user.apellido}</TableCell>
                        <TableCell>{user.pais}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.celular}</TableCell>
                        <TableCell>{user.cargo}</TableCell>
                        <TableCell>{user.institucion}</TableCell>
                      </TableRow>
                )}})}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;