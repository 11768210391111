import { SELECCIONAR_CHARLA } from '../types/index';

const Reducer = (state, action) => {
    switch(action.type) {

        case SELECCIONAR_CHARLA:
            return {
                ...state,
                charla: action.payload
            }

        default:
            return state;
    }
}
 
export default Reducer;