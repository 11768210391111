import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import axios from 'axios';
import charlaContext from '../../../../context/charlaContext';

import { TextField } from '@material-ui/core';
import clienteAxios from 'config/axios';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const UsersToolbar = props => {

  const CharlaContext = useContext(charlaContext);
  const {charla, cambiarCharla} = CharlaContext;

  const { titulosCharlas, className, ...rest } = props;

  const classes = useStyles();

  const handleChange = e => {
    cambiarCharla(e.target.value);
  }

  const descargarAsistentes = async () => {

    let url = '';
    let nombreDocumento = '';

    if(charla === 0) {
      url = `api/descargar/total/inscripciones`;
      nombreDocumento = `inscripciones_total.xlsx`;
    } else {
      url = `api/descargar//inscripciones/${charla}`;
      nombreDocumento = `${charla}_inscripciones.xlsx`;
    }

    axios({
      url: `${process.env.REACT_APP_BASE_URL}/${url}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', nombreDocumento);
       document.body.appendChild(link);
       link.click();
    });

  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <TextField
          fullWidth
          label="Charla / MasterTalk"
          margin="dense"
          name="charla"
          onChange={handleChange}
          required
          select
          style={{marginRight: '20px'}}
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={charla}
          variant="outlined"
        >
          <option value={0}>Todas</option>
          {
            (titulosCharlas !== undefined) ? (

              titulosCharlas.map((charla) => {
                return (
                  <option key={charla.id} value={charla.id} data-nombre={charla.es_titulo}>{charla.es_titulo} ({charla.inscriptos})</option>
                )}
              )

            ) : null
          }
        </TextField>
        <span className={classes.spacer} />
        <Button
          className={classes.exportButton}
          onClick={() => descargarAsistentes() }
        >Descargar</Button>
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
