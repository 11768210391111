import React, { useReducer } from 'react';
import charlaContext from './charlaContext';
import charlaReducer from './charlaReducer';
import { SELECCIONAR_CHARLA } from '../types/index';

const CharlaState = ({children}) => {
    const stateInicial = {
        charla: 0
    }

    const [ state, dispatch ] = useReducer(charlaReducer, stateInicial);

    // Functions
    const cambiarCharla = (seleccion) => {
        dispatch({
            type: SELECCIONAR_CHARLA,
            payload: Number(seleccion)
        });
    }

    return (
        <charlaContext.Provider
            value={{
                charla: state.charla,
                cambiarCharla
            }}
        >
            {children}
        </charlaContext.Provider>
    );
}
 
export default CharlaState;