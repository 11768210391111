import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import clienteAxios from '../../config/axios';

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  ComprasDash
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  aboutTalleres: {
    fontFamily: 'Roboto' 
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  const [estadisticas, setEstadisticas] = useState({
    totalCharlas: 0,
    totalInscripciones: 0,
    totalSuscriptores: 0,
    totalIngresos: 0,
    totalDescuentos: 0,
    totalCharlasVisibles: 0
  });

  useEffect(() => {
    const traerDatos = async () => {
      const estadisticasDB = await clienteAxios.get('/api/estadisticas/generales');
      setEstadisticas(estadisticasDB.data.estadisticas);
    }
    traerDatos();
  //eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Budget inscriptosNewsletter={estadisticas.totalSuscriptores} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers inscriptosCharlas={estadisticas.totalInscripciones} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress eventosTotales={estadisticas.totalCharlas} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit totalCharlasVisibles={estadisticas.totalCharlasVisibles} />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <h2 className={classes.aboutTalleres}>INGRESOS</h2>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComprasDash numero={estadisticas.totalIngresos} texto="SUBTOTAL" />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComprasDash numero={estadisticas.totalDescuentos} texto="DESCUENTOS HECHOS" />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComprasDash numero={estadisticas.totalIngresos - estadisticas.totalDescuentos} texto="TOTAL" />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
