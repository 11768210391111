import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import clienteAxios from '../../config/axios';
import CharlaState from '../../context/charlaState';

import { UsersToolbar, UsersTable } from './components';
// import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const InscripcionesList = () => {
  const [users, setUsers] = useState([]);
  const [titulosCharlas, setTitulosCharlas] = useState();
  const classes = useStyles();

  useEffect(()=> {
    const traerAsitentes = async () => {
      const asistentes = await clienteAxios.get('/api/inscripciones');
      setUsers(asistentes.data.resp);
    }

    async function traerNombresDeCharlas() {
      await clienteAxios.get('/api/charlas/nombre-id')
        .then(respuesta => {
          
          const arrayOrdenado = respuesta.data.resp.sort(function (a, b) {
            if(a.es_titulo > b.es_titulo) {
              return 1;
            } else if (a.es_titulo < b.es_titulo) {
              return -1;
            }
            return 0;
          })

          setTitulosCharlas(arrayOrdenado);
        })
        .catch(error => {
        })
    }

    traerNombresDeCharlas();
    traerAsitentes();
    // eslint-disable-next-line
  }, [])

  return (
    <CharlaState>
      <div className={classes.root}>
        <UsersToolbar titulosCharlas={titulosCharlas} />
        <div className={classes.content}>
          <UsersTable users={users} titulosCharlas={titulosCharlas} />
        </div>
      </div>
    </CharlaState>
  );
};

export default InscripcionesList;
